.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #000;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

.red {
  color: red;
}

.link-top {
  margin-top: 20px;
}

.link-bottom {
  margin-top: 60px;
}

.bib {
  font-size: 20px;
  margin-bottom: 40px;
}
