body {
  margin: 0;
  font-family: "arial",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img-fluid {
  width: 100%;
  height: auto;
}

#myCarousel .list-inline {
  white-space: nowrap;
  overflow-x: auto;
}

#myCarousel .carousel-indicators {
  position: static;
  left: initial;
  width: initial;
  margin-left: initial;
}

#myCarousel .carousel-indicators>li {
  width: initial;
  height: initial;
  text-indent: initial;
}

#myCarousel .carousel-indicators>li.active img {
  opacity: 0.7;
}

.control-dots {
  display: none;
}

h1,
h2,
h3 {
  font-weight: bold;
  font-size: 16px;
  margin: 20px 0 0 0;
}

.champ-photo {
  margin: 20px 0 0 0;
  padding-left: 0;
}

.champ-photo>.col-md-4 {
  padding-left: 0;
}

.sub-head {
  font-weight: bold;
  font-size: 16px;
  color: #666;
  margin-top: 0;
  margin-bottom: 40px;
}

a {
  color: red;
  font-weight: bold;
  text-decoration: none;
}


li {
  list-style-type: none;
}

.champs-header-main {
  margin-bottom: 30px;
}

.navbar-nav {
  margin-right: 20px;
  font-size: 16px;
}

#basic-nav-dropdown {
  color: red;
  padding: 0;
  margin-right: 20px;
  font-size: 16px;
}

.dropdown-item>a,
.navbar-toggler {
  color: red;
  font-size: 16px;
}


.navbar-toggler.collapsed,
.collapse.show {
  color: red;
}

.navbar-toggler-icon {
  background-color: red;
}

.header-prob {
  margin-top: 20px;
}

.title-bold {
  font-size: 18px;
  font-weight: bold;
}

.teams>p {
  font-weight: bold;
}

ul {
  padding: 0;
}

.caption {
  font-weight: bold;
  line-height: 17px;
  font-size: 14px;
}



@media only screen and (max-width: 600px) {
  .header-img {
    width: 100%;
    height: auto;
  }
}
